import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Brand } from '../models/Brand';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  private apiUrl: string = environment.apiUrl;

  public brand!: Brand;

  constructor(
    private http: HttpClient,
  ) { }

  createBrand(brand: Brand) {
    return this.http.post(this.apiUrl.concat('/brands/'), { ...brand }).pipe(tap((result: any) => this.brand = result));
  }

  getBrandByUserId() {
    return this.http.get(this.apiUrl.concat('/brand-by-user-id')).pipe(tap((result: any) => this.brand = result));
  }

  getStaticDomainStatus() {
    return this.http.get(this.apiUrl.concat('/static-domain-status'));
  }
}
