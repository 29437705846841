// ===========================================================================
// File: APP.MODULE-PRIMENG.ts
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputNumberModule } from 'primeng/inputnumber';
import { BadgeModule } from 'primeng/badge';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ListboxModule } from 'primeng/listbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { DividerModule } from 'primeng/divider';
import { StyleClassModule } from 'primeng/styleclass';
import { ChartModule } from 'primeng/chart';
import { PasswordModule } from 'primeng/password';

export const APP_PRIMENG_MODULE = [
  TableModule,
  DialogModule,
  DropdownModule,
  MenubarModule,
  ButtonModule,
  PanelModule,
  CalendarModule,
  CheckboxModule,
  OverlayPanelModule,
  InputNumberModule,
  BadgeModule,
  InputTextModule,
  SelectButtonModule,
  ListboxModule,
  RadioButtonModule,
  ToastModule,
  MessagesModule,
  MessageModule,
  ProgressSpinnerModule,
  AutoCompleteModule,
  TooltipModule,
  MultiSelectModule,
  DividerModule,
  StyleClassModule,
  ChartModule,
  PasswordModule,
];
