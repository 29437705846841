import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { FidevoUser } from '../shared/models/FidevoUser';
import { BrandService } from '../modules/back-office/shared-back-office/services/brand.service';
import { jwtDecode } from 'jwt-decode';

export interface JWTPayload {
  token_type: string,
  exp: number,
  jti: string,
  user_id: string
}

export interface User {
  username: string,
  password: string
}

@Injectable()
export class AuthService {
  
  private apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private brandService: BrandService
  ) { }

  private setSession(authResult: any) {
    const payload = <JWTPayload> jwtDecode(authResult.token);
    const expiresAt = moment.unix(payload.exp);

    localStorage.setItem('tk', authResult.token);
    localStorage.setItem('tk_exp', JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('uid', authResult.user.id)

    if (authResult.user.onBoardingStep) {
      localStorage.setItem('onboarding_step', authResult.user.onBoardingStep);
    }
  }

  get token() {
    return localStorage.getItem('tk');
  }

  get tokenExpiration() {
    return localStorage.getItem('tk_exp');
  }

  login(username: string, password: string) {
    return this.http.post(this.apiUrl.concat('/owner-login'), { username, password }).
      pipe(
        tap((response: any) => {
          this.setSession(response);
        }),
        shareReplay(),
      );
  }

  logout() {
    this.router.navigate(['login']);
    
    localStorage.removeItem('tk');
    localStorage.removeItem('tk_exp');
    localStorage.removeItem('uid');
    localStorage.removeItem('onboarding_step');
  }

  tokenExpired() {
    if (this.tokenExpiration) {
      return (Math.floor((new Date).getTime())) >= parseInt(this.tokenExpiration);
    }
    return true;
  }
  
  isLoggedIn() {
    if (!!this.token && !this.tokenExpired()) {
      return true;
    }
    return false;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  signup(data: FidevoUser) {
    return this.http.post(this.apiUrl.concat('/owner-user'), data)
      .pipe(
        tap((response: any) => {
          this.setSession(response);
        }),
        shareReplay(),
      );
  }

  loginRedirect() {
    if (localStorage.getItem('onboarding_step')) {
      const onboardingStep = localStorage.getItem('onboarding_step') || '';
      
      switch (onboardingStep) {
        case '1': {
          this.router.navigate(['onboarding', 'account-type']);
          break;
        }
        case '2': {
          this.router.navigate(['onboarding', 'brand-details']);
          break;
        }
        case '3': {
          this.router.navigate(['onboarding', 'coffee-shops']);
          break;
        }
        case '4': {
          this.router.navigate(['onboarding', 'select-plan']);
          break;
        }
        default: break;
      }

      return;
    }


    if (this.brandService.brand?.staticDomain) {
      window.location.href = `https://${this.brandService.brand.staticDomain}/manager?uid=${localStorage.getItem('uid')}&tk=${localStorage.getItem('tk')}&tk_exp=${localStorage.getItem('tk_exp')}`;
    } else {
      this.brandService.getBrandByUserId().subscribe(
        _ => {
          window.location.href = `https://${this.brandService.brand.staticDomain}/manager?uid=${localStorage.getItem('uid')}&tk=${localStorage.getItem('tk')}&tk_exp=${localStorage.getItem('tk_exp')}`;
        }
      )
    }
  }
}
